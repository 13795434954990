import {gql} from "@apollo/client";

export const GET_OUTGOING_PDF = gql`
  query getOutgoingCertificate(
    $siteReferenceId: String
    $divisionId: String
    $mbOutboundQtyEuId: String
  ) {
    bioLcGetGeneratedCertificate(
      event: {
        divisionId: $divisionId
        mbOutboundQtyEuId: $mbOutboundQtyEuId
        siteReferenceId: $siteReferenceId
      }
    ) {
      downloadUrl
      statusCode
      certificateNumber
    }
  }
`;

export default GET_OUTGOING_PDF;
