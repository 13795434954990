import {useQuery} from "@apollo/client";
import {GET_OUTGOING_PDF} from "graphql/MassBalance/Actions/OutgoingCertificate";
import Layout from "modules/GlobalMassBalance/components/Layout";
import {useAppSetting} from "providers/appSetting";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {
  getDivisionData,
  getSiteDetails,
  setCountryDetails,
} from "utils/helpers/getAppSetting.js";

import {Button} from "reactstrap";

const ViewCertificatePage = () => {
  const navigate = useNavigate();
  const {appSetting} = useAppSetting();

  const {
    country,
    division,
    type,
    location,
    period,
    balance,
    siteId,
    outId,
    document,
  } = useParams();
  const divData = getDivisionData(division);

  const [searchParams] = useSearchParams();
  const navigatedFromPage = searchParams.get("page") ?? "mass-balance";
  const pageIndex = searchParams.get("pageNo") ?? 0;

  setCountryDetails(country);
  const countryId = appSetting?.currentCountryMappingData?.countryId;
  const siteReferenceData = getSiteDetails(countryId);

  const mbRedirectUrl = `/mass-balance/${country}/${division}/${type}/${location}/${period}/${balance}`;
  const outgoingRedirectUrl = `/doc-manager/outgoing/${country}`;

  const backButtonUrl =
    navigatedFromPage === "outgoing" ? outgoingRedirectUrl : mbRedirectUrl;
  const backButtonLabel =
    navigatedFromPage === "outgoing" ? "Outgoing documents" : "Mass balance";

  // get version details
  const {data: pdfData, loading: pdfLoading} = useQuery(GET_OUTGOING_PDF, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      divisionId: divData?.divisionId,
      mbOutboundQtyEuId: outId,
      siteReferenceId: siteReferenceData?.siteReferenceId,
    },
  });

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},

    {text: backButtonLabel, link: backButtonUrl},
    {
      text: pdfData?.bioLcGetGeneratedCertificate?.certificateNumber,
    },
  ];

  return (
    <div className="flex flex-col view-doc__max-scroll bp-core bg-light-gray-100">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title="View Document"
            breadcrumbs={breadcrumbItems}
            subtitle=""
          >
            <div className="flex justify-end m-7">
              <Button
                color="tertiary"
                className="show"
                style={{
                  borderRadius: "0px",
                  borderColor: "#cccccc",
                }}
                type="button"
                onClick={() =>
                  navigate({
                    pathname: backButtonUrl,
                    search: `?pageNo=${pageIndex}`,
                  })
                }
              >
                Back
              </Button>
            </div>
          </Layout>
        </div>
        <div className="flex flex-col flex-1 items-start justify-stretch bg-light-gray-100">
          {pdfData?.bioLcGetGeneratedCertificate?.downloadUrl != null &&
          pdfData?.bioLcGetGeneratedCertificate?.downloadUrl?.includes(
            "file-not-found",
          ) ? (
            <div className="flex flex-col p-5 w-full text-center">
              <span className="fs-4 text-danger">Unable to load file</span>
              <code className="fs-6 text-danger">File not found</code>
            </div>
          ) : (
            <iframe
              className="w-full min-h-screen"
              title="View document"
              border="0"
              src={pdfData?.bioLcGetGeneratedCertificate?.downloadUrl}
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
};

export default ViewCertificatePage;
