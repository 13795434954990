import {Remove32} from "@bphxd/ds-core-react/lib/icons";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {Button, Input, Modal, ModalBody, ModalFooter} from "reactstrap";
import {cost_calc_urls} from "../../constants/common";
import {formatNumber} from "../../utils";
import "./ActualisationModalStyle.css";

const ActualisationModal = ({
  massBalanceObject,
  showModal,
  setShowModal,
  incomingStatus,
  setRowSelection,
  setPurchaseRowSelection,
  apiSuccess,
  incomingTotal,
  receivedTotal,
  posIds,
  purchaseIds,
  actualizeSplitApi,
  divisionId,
  siteReferenceId,
}) => {
  const navigate = useNavigate();
  const {country} = useParams();

  const LINK_ANOTHER_HEADER = "Link another";
  const TRY_AGAIN_HEADER = "Try again";
  const INCOMING_DOCUMENT_HEADER = "Incoming document";
  const MASS_BALANCE_HEADER = "Mass balance";
  const LINK_REMAINING_HEADER = "Link and keep remaining quantity";
  const LINK_FULL_HEADER = "Link with full quantity";
  const LINK_WRITE_OFF_EXCESS_HEADER = "Link and write off excess quantity";
  const CANCEL_HEADER = "Cancel";
  const CONFIRM_LINKING_HEADER = "Confirm linking";

  const [showFirstModal, setShowFirstModal] = useState(true);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [secondModalHeader, setSecondModalHeader] = useState("");

  let Header;
  if (incomingStatus < 3) {
    Header = "Successfully linked";
  } else {
    Header = "Quantities do not match";
  }

  const handleLinkRefresh = () => {
    setShowModal(false);
  };

  const redirectToIncomingDocPage = () => {
    navigate({
      pathname: cost_calc_urls[`${country}DocumentPage`],
    });
  };

  const redirectToMassBalancePage = () => {
    navigate({
      pathname:
        cost_calc_urls[`${country}MassBalancePage`] +
        `/${massBalanceObject?.division}` +
        `/${massBalanceObject?.type}` +
        `/${massBalanceObject?.location}` +
        `/${massBalanceObject?.period}` +
        `/${massBalanceObject?.balance}`,
    });
  };

  const handleRemainingQuantity = () => {
    setSecondModalHeader(LINK_REMAINING_HEADER);
    setShowFirstModal(false);
    setShowSecondModal(true);
  };

  const handleFullQuantity = () => {
    setSecondModalHeader(LINK_FULL_HEADER);
    setShowFirstModal(false);
    setShowSecondModal(true);
  };

  const handleWriteOffExcessQuantity = () => {
    setSecondModalHeader(LINK_WRITE_OFF_EXCESS_HEADER);
    setShowFirstModal(false);
    setShowSecondModal(true);
  };

  const cancel_button_header = CANCEL_HEADER;
  const cancel_button_function = handleLinkRefresh;

  const confirm_linking_header = CONFIRM_LINKING_HEADER;

  let split_type = "";
  if (secondModalHeader === LINK_REMAINING_HEADER) {
    split_type = "remainder";
  } else if (secondModalHeader === LINK_FULL_HEADER) {
    split_type = "full_match";
  } else if (secondModalHeader === LINK_WRITE_OFF_EXCESS_HEADER) {
    split_type = "write_off";
  } else {
    split_type = "";
  }

  const [remarks, SetRemarks] = useState("");

  const updateRemarks = (event) => {
    SetRemarks(event.target.value);
  };

  const confirm_linking_function = () => {
    actualizeSplitApi({
      variables: {
        event: {
          division_id: divisionId,
          site_reference_id: siteReferenceId,
          pos_ids: posIds,
          purchase_ids: purchaseIds,
          split_type,
          remarks,
        },
      },
    });
    handleLinkRefresh();
  };

  let first_button_header;
  const first_button_function = handleLinkRefresh;
  if (incomingStatus < 3) {
    first_button_header = LINK_ANOTHER_HEADER;
  } else {
    first_button_header = TRY_AGAIN_HEADER;
  }

  let second_button_header;
  let second_button_function;
  if (incomingStatus < 3) {
    second_button_header = INCOMING_DOCUMENT_HEADER;
    second_button_function = redirectToIncomingDocPage;
  } else if (incomingStatus === 3) {
    second_button_header = LINK_REMAINING_HEADER;
    second_button_function = handleRemainingQuantity;
  } else {
    second_button_header = LINK_WRITE_OFF_EXCESS_HEADER;
    second_button_function = handleWriteOffExcessQuantity;
  }

  let third_button_header;
  let third_button_function;
  if (incomingStatus < 3) {
    third_button_header = MASS_BALANCE_HEADER;
    third_button_function = redirectToMassBalancePage;
  } else if (incomingStatus === 3) {
    third_button_header = LINK_FULL_HEADER;
    third_button_function = handleFullQuantity;
  }

  let Body;
  let Body2;
  let Body3;

  if (incomingStatus === 1) {
    Body = "Successfully linked document quantity with received quantity.";
  } else if (incomingStatus === 2) {
    Body =
      "Successfully linked document quantity with received quantity within the 0.5% tolerance range as governed by ISCC.";
  } else if (incomingStatus >= 3) {
    Body = "Quantities are more than 0.5% misaligned.";
    Body2 =
      "Please check the quantities you are trying to link and try again, or reject the document if there are any errors.";
    Body3 =
      "If you want to proceeed, you must provide a reason for matching outside of 0.5% tolerance.";
  } else {
    Body = `Linking a purchase quantity higher than the PoS quantity is not possible.`;
  }

  if (!apiSuccess) {
    Body2 =
      "Please check the quantities you are trying to link and try again, or reject the PoS if there is an error.";
  }

  const closePopupAndRefresh = () => {
    setRowSelection({});
    setPurchaseRowSelection({});
    setShowModal(false);
  };

  return (
    <Modal
      isOpen={showModal}
      className="modal-dialog-centered"
      style={{width: showFirstModal ? "285px" : "360px"}}
    >
      {showFirstModal && (
        <ModalBody className="text-center">
          <p className="mb-[2px]">{Header}</p>
          <p className="text-[13px] text-gray-800 mb-0">{Body} </p>
          <>
            <br />
            <p className="text-[13px] text-gray-800 mb-0">{Body2} </p>

            {incomingStatus >= 3 && (
              <>
                <br />
                <p className="text-[13px] text-gray-800 mb-0">{Body3}</p>
              </>
            )}
          </>
        </ModalBody>
      )}
      {!apiSuccess && (
        <>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={first_button_function}
            >
              {first_button_header}
            </Button>
          </ModalFooter>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={() => {
                setRowSelection({});
                setPurchaseRowSelection({});
                setShowModal(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
      {apiSuccess && showFirstModal && (
        <>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={first_button_function}
            >
              {first_button_header}
            </Button>
          </ModalFooter>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              onClick={second_button_function}
            >
              {second_button_header}
            </Button>
          </ModalFooter>
          <ModalFooter className="justify-center p-0">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent w-full"
              style={{display: incomingStatus <= 3 ? "block" : "none"}}
              onClick={third_button_function}
            >
              {third_button_header}
            </Button>
          </ModalFooter>
        </>
      )}
      {apiSuccess && showSecondModal && (
        <>
          <ModalBody className="text-center">
            <span
              className="plead"
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                textAlign: "left",
              }}
            >
              {secondModalHeader}
              <Remove32
                className="btn-icon-suffix plead-img"
                style={{marginLeft: "8px"}}
                onClick={handleLinkRefresh}
              />
            </span>
          </ModalBody>
          <div className="border-bottom"></div>
          <ModalBody>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <label className="label" style={{textAlign: "left"}}>
                  Document Quantity
                </label>
                <span className="mt-values" style={{textAlign: "right"}}>
                  {formatNumber(incomingTotal, "", 3)} MT
                </span>
              </div>
              <div style={{display: "flex", justifyContent: "space-between"}}>
                <label className="label" style={{textAlign: "left"}}>
                  Received Quantity
                </label>
                <span className="mt-values" style={{textAlign: "right"}}>
                  {formatNumber(receivedTotal, "", 3)} MT
                </span>
              </div>
            </div>
            <div style={{marginTop: "24px"}}>
              <label className="input-text-label">
                Reason for keeping remaining quantity
              </label>
              <Input
                type="textarea"
                rows="3"
                id="additional_notes"
                data-test="additional_notes"
                onChange={updateRemarks}
              />
              <span className="help-text">
                Provide reason and evidence to support linking outside of 0.5%
                tolerance
              </span>
            </div>
          </ModalBody>
          <ModalFooter className="flex items-center justify-center p-0 space-x-2">
            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent flex-1"
              onClick={cancel_button_function}
            >
              {cancel_button_header}
            </Button>

            <div
              className="h-6 border-r border-gray-300 mx-2"
              style={{margin: "0px"}}
            ></div>

            <Button
              color="darker-tertiary"
              size="lg"
              className="border-0 rounded-0 opacity-80 opacity-100-hover bg-transparent flex-1"
              onClick={confirm_linking_function}
            >
              {confirm_linking_header}
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

ActualisationModal.propTypes = {
  massBalanceObject: PropTypes.object,
  showModal: PropTypes.bool,
  setShowModal: PropTypes.func,
  incomingStatus: PropTypes.number,
  setRowSelection: PropTypes.func,
  setPurchaseRowSelection: PropTypes.func,
  apiSuccess: PropTypes.bool,
  incomingTotal: PropTypes.number,
  receivedTotal: PropTypes.number,
  posIds: PropTypes.array,
  purchaseIds: PropTypes.array,
  actualizeSplitApi: PropTypes.func,
  divisionId: PropTypes.string,
  siteReferenceId: PropTypes.string,
};

export default ActualisationModal;
