import {useQuery} from "@apollo/client";
import {LOCATION_YIELD_GMB} from "graphql/MassBalance/bioLcGetLocationYieldDetailsDataApi";
import {UpdateDocAPI} from "graphql/MassBalance/bioLcMassBalanceCustomYield";
import {isEqual, uniqWith} from "lodash";
import PropTypes from "prop-types";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {FormGroup, Input, Label} from "reactstrap";

const CoproDetail = ({data}) => {
  const {data: yieldDataRes} = useQuery(LOCATION_YIELD_GMB, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  const [appliedYieldRatio, setAppliedYieldRatio] = useState(
    `${data?.inboundRecord?.appliedYieldType}-${data?.inboundRecord?.appliedYieldRatio}`,
  );

  const [appliedProcessingUnit, setAppliedProcessingUnit] = useState(
    data?.inboundRecord?.appliedProcessingUnit,
  );

  const yieldData = yieldDataRes?.bioLcGetLocationYieldDetailsDataApi?.data
    ?.filter(
      (item) =>
        item?.mbLocationGroupId ===
          data?.inboundRecord?.mbLocationGroupId.toString() &&
        item?.mbBalanceGroupId === data?.inboundRecord?.mbBalanceGroupId,
    )
    .sort();

  const yieldDataRatioAndTypeOnly = yieldData?.map((data) => {
    return {
      yieldRatio: data.yieldRatio,
      yieldType: data.yieldType,
    };
  });

  const yiledDataFilteredListForDropdown = uniqWith(
    yieldDataRatioAndTypeOnly,
    isEqual,
  ).sort((a, b) =>
    a.yieldType === "ISCC" ? -1 : b.yieldType === "ISCC" ? 1 : 0,
  );

  const processingUnitData = yieldData?.map((data) => {
    const processingUnits = data.processingUnitCode;
    return processingUnits;
  });

  const processingUnitsFilteredListForDropdown = uniqWith(
    processingUnitData,
    isEqual,
  ).sort((a, b) => (a === "MAC" ? -1 : b === "MAC" ? 1 : 0));

  function yieldThreeDecimalConversion(value) {
    if (!value) return null;

    const number = value + "00";
    return number;
  }

  const handleProcessingUnitChange = async (processingUnit) => {
    if (processingUnit !== "") {
      setAppliedProcessingUnit(processingUnit);
      const {data: response} = await UpdateDocAPI({
        event: {
          eventName: "processing_unit",
          appliedProcessingUnit: processingUnit,
          mbInboundQtyEuId: data?.inboundRecord?.mbInboundQtyEuId,
        },
      });
      if (response?.bioLcMassBalanceCustomYield?.statusCode === 200) {
        toast.success("Processing unit updated successfully");
      }
      if (response?.bioLcMassBalanceCustomYield?.statusCode !== 200) {
        toast.error(response?.bioLcMassBalanceCustomYield?.message);
      }
    }
  };

  const handleYieldRatioChange = async (selectedData) => {
    if (selectedData !== "") {
      const yieldType = selectedData.split("-")[0];
      const yieldRatio = selectedData.split("-")[1];
      setAppliedYieldRatio(yieldRatio);
      const {data: response} = await UpdateDocAPI({
        event: {
          eventName: "yield_ratio",
          mbInboundQtyEuId: data?.inboundRecord?.mbInboundQtyEuId,
          appliedYieldRatio: yieldRatio,
          appliedYieldType: yieldType,
        },
      });
      if (response?.bioLcMassBalanceCustomYield?.statusCode === 200) {
        toast.success("Yield ratio updated successfully");
      }
      if (response?.bioLcMassBalanceCustomYield?.statusCode !== 200) {
        toast.error(response?.bioLcMassBalanceCustomYield?.message);
      }
    }
  };

  return (
    <div className="flex flex-col h-[260px] justify-start items-stretch w-full gap-4">
      <div className="flex flex-row justify-between items-center h-[2.2rem]"></div>
      <div>
        <FormGroup>
          <Label for="processingUnit" className="!mb-[6px]">
            Processing unit
          </Label>
          <Input
            className="bg-white !text-[#111111a3] mb-[40px] !text-[13px] min-w-[180px]  max-w-[180px]"
            type="select"
            id="processingUnit"
            data-test="processingUnit"
            placeholder="Please choose from list"
            onChange={(e) => handleProcessingUnitChange(e.target.value)}
            value={appliedProcessingUnit}
            disabled={!data?.inboundRecord?.transportationLossEnableFlag}
          >
            {processingUnitsFilteredListForDropdown?.map((processingUnit) => (
              <option key={processingUnit} value={processingUnit}>
                {processingUnit}
              </option>
            ))}
          </Input>
        </FormGroup>
        <FormGroup>
          <Label for="yieldPercent" className="!mb-[6px]">
            Yield %
          </Label>
          <Input
            className="bg-white !text-[#111111a3] !text-[13px] min-w-[180px]  max-w-[180px]"
            type="select"
            id="yieldPercent"
            data-test="yieldPercent"
            placeholder="Please choose from list"
            onChange={(e) => handleYieldRatioChange(e.target.value)}
            value={appliedYieldRatio}
            disabled={!data?.inboundRecord?.transportationLossEnableFlag}
          >
            {yiledDataFilteredListForDropdown?.map((yieldDataItem) => (
              <option
                key={yieldDataItem.yieldRatio}
                value={`${yieldDataItem.yieldType}-${yieldDataItem.yieldRatio}`}
              >
                {`${
                  yieldDataItem.yieldType
                } Yield - ${yieldThreeDecimalConversion(
                  yieldDataItem.yieldRatio,
                )}`}
              </option>
            ))}
          </Input>
        </FormGroup>
      </div>
    </div>
  );
};

CoproDetail.propTypes = {
  data: PropTypes.object,
};

export default CoproDetail;
