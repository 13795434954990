import {gql} from "@apollo/client";

export const TRANSACTION_API = gql`
  query bioLcGetMassBalanceTransactionData(
    $siteReferenceId: String
    $divisionId: String
    $mbBalanceTypeId: String
    $mbLocationGroupId: String
    $mbPeriodStatusId: String
    $mbBalanceGroupId: String
  ) {
    bioLcGetMassBalanceTransactionData(
      event: {
        divisionId: $divisionId
        siteReferenceId: $siteReferenceId
        mbBalanceTypeId: $mbBalanceTypeId
        mbLocationGroupId: $mbLocationGroupId
        mbPeriodStatusId: $mbPeriodStatusId
        mbBalanceGroupId: $mbBalanceGroupId
      }
    ) {
      statusCode
      error
      mbTransactionData {
        outboundRecords {
          calculationSteps
          document
          ghgEccr
          ghgEccs
          ghgEec
          ghgEee
          ghgEl
          ghgEmissionIn
          ghgEp
          ghgEsca
          ghgEu
          ghgEtd
          ghgSavingsOutgoing
          ghgTotal
          ghgTotalMjOutgoing
          issuanceDateOutgoing
          mbOutboundQtyEuId
          outboundType
          product
          quantityOutM3
          quantityOutMt
          recipient
          status
          transportSystemOutgoing
          vesselNameOutgoing
          linkError
        }
        inboundRecord {
          actualizationId
          actualizedQuantityMt
          actualizedQuantityM3
          appliedProcessingUnit
          appliedYieldRatio
          appliedYieldType
          ghgEccs
          calculationSteps
          certified
          certificateInboundEuId
          coProcessedQuantityMt
          countryOfOrigin
          fromMbLocationGroupId
          ghgEccr
          ghgEec
          ghgEee
          ghgEl
          ghgEmissionIn
          ghgEmissionOut
          ghgEp
          ghgEsca
          ghgEtd
          ghgEu
          ghgSavings
          ghgTotal
          ghgTotalMj
          incomingDocument
          invoiceNumber
          issuanceDate
          mbBalanceGroupId
          mbBalanceTypeId
          mbConfigInstanceId
          mbInboundQtyEuId
          mbLocationGroupId
          mbPeriodStatusId
          physicalReceiptDate
          productQtyM3
          productQtyMt
          rawMaterial
          receivedQuantityMt
          supplier
          transportLg
          transportSystem
          transportationLossEnableFlag
          vesselName
        }
      }
    }
  }
`;

export default TRANSACTION_API;
