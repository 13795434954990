import {useMutation, useQuery} from "@apollo/client";
import {useAccount, useMsal} from "@azure/msal-react";
import {
  Alert32,
  Check24,
  Remove24,
  TailLeft24,
} from "@bphxd/ds-core-react/lib/icons";
import {DIV_CODE_COPRO, DIV_CODE_SAF} from "constants/divisionDetails";
import {
  DOCUMENT_DETAIL_VIEW,
  UPDATE_DOCUMENT,
} from "graphql/docManager/IncomingDocs";
import {startCase} from "lodash";
import process from "process";
import {useAppSetting} from "providers/appSetting/context.js";
import {
  convertToNumber,
  formatNumber,
  useUserSettings,
} from "providers/userSettings";
import {useCallback, useEffect, useMemo, useState} from "react";
import {FormProvider, useForm} from "react-hook-form";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {toast} from "react-toastify";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import {
  getDivisionDetailsById,
  setCountryModuleDetails,
} from "utils/helpers/getAppSetting";

import Datepicker from "../components/Datepicker";
import Layout from "../components/Layout";
import {defaultValues, getProps} from "../content/DefaultValues";
import {rejectReasons, tabFields} from "../content/ValidationFields";

import GHGEmissionInfo from "../components/view-document/GHGEmissionInfo";
import GeneralInformation from "../components/view-document/GeneralInformation";
import ScopeOfCertification from "../components/view-document/ScopeOfCertification";
import SingleDocumentViewer from "../components/view-document/SingleDocumentViewer";
import Subtitle from "../components/view-document/Subtitle";
import SupplierAndRecipientInfo from "../components/view-document/SupplierAndRecipientInfo";
import SupplimentaryInfo from "../components/view-document/SupplimentaryInfo";
import {DDV, TDV} from "../components/view-document/utils";

import "./view-document.css";

import {
  getViewDocumentDetailsACCEPT,
  getViewDocumentDetailsREJECT,
} from "../content/getViewDocumentDetails";
import "./styles.scss";

const None = "none";
const Accepting = "accepting";
const Rejecting = "rejecting";

const DocumentDetailedView = () => {
  const {appSetting} = useAppSetting();
  const {country: countryName} = useParams();
  const navigate = useNavigate();
  const {
    userSettings: {dateFormat, decimalFormat},
  } = useUserSettings();
  setCountryModuleDetails(countryName, "documentManager");
  const [submitLoading, setSubmitLoading] = useState(false);
  const [rejecting, setRejecting] = useState(false);
  const [rejectMessage, setRejectMessage] = useState("");
  const [acceptModal, setAcceptModal] = useState(false);
  const [searchParams] = useSearchParams();
  const {accounts} = useMsal();
  const account = useAccount(accounts[0]);
  const {docId, country: selectedCountry} = useParams();
  const [open, setOpen] = useState([]);
  const [operation, setOperation] = useState(None);
  const [selectedOption, setSelectedOption] = useState("");
  const [invalidTabs, setInvalidTabs] = useState({
    supplier: false,
    general: false,
    emission: false,
    certification: false,
    additionalInfo: false,
  });
  const [openTabsOnce, setOpenTabsOnce] = useState(false);

  const siteReferenceId = searchParams.get("siteReferenceId") ?? 0;
  const divisionId = searchParams.get("divisionId") ?? 0;
  const divisionCode = getDivisionDetailsById(divisionId);
  const divCode = divisionCode?.divisionCode;

  const DOCUMENT_MANAGER_PAGE = "Document manager Page";
  const LINK_TO_PURCHASE_PAGE = "Link to purchase Page";

  const hideLtpButton =
    process.env.REACT_APP_COPRO_LINK_TO_PURCHASE_HIDE_VISIBILITY;

  const toggle = (id) => {
    if (open.includes(id)) {
      setOpen(open.filter((item) => item !== id));
    } else {
      setOpen([...open, id]);
    }
  };
  const openAccordion = (id) => {
    if (!open.includes(id)) {
      setOpen([...open, id]);
    }
  };

  const {
    data: documentDataItem,
    loading,
    refetch,
  } = useQuery(DOCUMENT_DETAIL_VIEW, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: "network-only",
    variables: {
      divisionId,
      certificateInboundEuId: docId,
      siteReferenceId,
    },
  });

  const documentData =
    documentDataItem?.bioLcGetIncomingDocDetailsApi?.documentDetails;

  const documentFile = documentDataItem?.bioLcGetIncomingDocFileApi;

  const docValues = useMemo(() => {
    if (documentData != null) {
      const props = getProps(
        documentData,
        dateFormat,
        decimalFormat,
        formatNumber,
      );
      return props;
    }
    return defaultValues;
  }, [documentData, dateFormat, decimalFormat]);

  const methods = useForm({
    defaultValues,
    mode: "onChange",
    values: docValues,
    delayError: 2000,
    shouldFocusError: true,
  });
  const {
    register,
    formState: {errors},
    getValues,
    resetField,
    setError,
    trigger,
    setFocus,
    control,
    watch,
    clearErrors,
    setValue,
  } = methods;

  const hasErrors = Object.keys(errors).length > 0;

  const handleErrorMessageClick = (errorValue) => {
    const errValue = errorValue === "ghgNoValue" ? "ghgEec" : errorValue;
    let match = null;
    const tabs = Object.keys(tabFields);
    for (let index = 0; index < tabs.length; index += 1) {
      const element = tabs[index];
      if (tabFields[element].includes(errValue)) {
        match = element;
        break;
      }
    }
    openAccordion(match);
    setTimeout(() => setFocus(errValue), 150);
  };

  const [updateDocument] = useMutation(UPDATE_DOCUMENT, {
    onCompleted: ({bioLcUpdateIncomingDocDataApi}) => {
      if (bioLcUpdateIncomingDocDataApi?.statusCode === 204) {
        if (operation === Accepting) {
          toast.success("Document updated successfully");
        } else {
          toast.success("Document has been rejected successfully");
        }
        navigate({
          pathname: `/doc-manager/${selectedCountry}/`,
          search: `?pageNo=${searchParams.get("pageNo") ?? 0}&docId=${docId}`,
        });
      } else if (
        bioLcUpdateIncomingDocDataApi?.errors &&
        bioLcUpdateIncomingDocDataApi?.errors?.length > 0
      ) {
        const errorFields = bioLcUpdateIncomingDocDataApi?.errors.map(
          (error) => error.field,
        );
        if (errorFields.includes("mbPeriodStatus")) {
          toast.error(
            "There is no open period for the given Date of dispatch. Please review your selection.",
          );
          setValue("materialDispatchDatez", undefined);
          setError("materialDispatchDatez", {
            type: "manual",
            message: "No open period found for the given Date of dispatch.",
          });
          handleErrorMessageClick("materialDispatchDatez");
        } else {
          bioLcUpdateIncomingDocDataApi.errors.forEach((element) => {
            resetField(element.field);
            setError(element.field, {
              type: "manual",
              message: element.message,
            });
          });
          handleErrorMessageClick(
            bioLcUpdateIncomingDocDataApi?.errors[0].field,
          );
          // Check if both mbLocationGroupId and mbBalanceGroupId are in errorFields
          const hasLocationAndBalanceErrors =
            errorFields.includes("mbLocationGroupId") &&
            errorFields.includes("mbBalanceGroupId");

          if (hasLocationAndBalanceErrors) {
            // Common error message for issues with Location and Balance
            toast.error(
              "There is no mass balance for the given combination Location and Balance. Please review your selection.",
            );

            // Additional error message if there are more than 2 errors
            if (errorFields.length > 2) {
              toast.error("Failed to update document");
            }
          } else if (errorFields.length) {
            // General error message for other update failures
            toast.error("Failed to update document");
          }
        }
      } else if (bioLcUpdateIncomingDocDataApi?.error) {
        toast.error(bioLcUpdateIncomingDocDataApi?.error);
      }
      setAcceptModal(false);
      setSubmitLoading(false);
      setRejecting(false);
    },
  });

  const ghgEecWatch = watch("ghgEec");
  const ghgElWatch = watch("ghgEl");
  const ghgEpWatch = watch("ghgEp");
  const ghgEtdWatch = watch("ghgEtd");
  const ghgWuWatch = watch("ghgEu");
  const ghgEscaWatch = watch("ghgEsca");
  const ghgEccsWatch = watch("ghgEccs");
  const ghgEccrWatch = watch("ghgEccr");

  useEffect(() => {
    const ghgWatches = [
      ghgEecWatch,
      ghgElWatch,
      ghgEpWatch,
      ghgEtdWatch,
      ghgWuWatch,
      ghgEscaWatch,
      ghgEccsWatch,
      ghgEccrWatch,
    ];

    const allZero = ghgWatches.every(
      (item) => convertToNumber(item || "0", decimalFormat) === 0,
    );

    if (allZero) {
      setError("ghg_no_value", {
        type: "value",
        message: "At least one GHG emission must be greater than 0",
      });
    } else {
      clearErrors("ghg_no_value");
    }
  }, [
    clearErrors,
    ghgEecWatch,
    ghgElWatch,
    ghgEpWatch,
    ghgEtdWatch,
    ghgWuWatch,
    ghgEscaWatch,
    ghgEccsWatch,
    ghgEccrWatch,
    setError,
    watch,

    decimalFormat,
  ]);

  const errorCount = Object.keys(errors).length;

  const validate = useCallback(async () => {
    const valid = await trigger();
    const values = getValues();
    let isOthersValid = true;
    if (
      (!values.splitDetails || values.splitDetails?.length === 0) &&
      values.physicalReceiptDate === "" &&
      divisionCode === DIV_CODE_COPRO &&
      hideLtpButton !== "false"
    ) {
      setError("physicalReceiptDate", {
        type: "value",
        message: "Please enter a physical receipt date",
      });
      isOthersValid = false;
    }
    if (values.materialDispatchDatez === "") {
      setError("materialDispatchDatez", {
        type: "value",
        message: "Please enter date of dispatch",
      });
    }
    if (
      (!values.splitDetails || values.splitDetails?.length === 0) &&
      values.defaultValueAppliedFlag !== true &&
      convertToNumber(values.ghgEccr ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEccs ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEec ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEee ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEl ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEp ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEsca ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEtd ?? "0", decimalFormat) === 0 &&
      convertToNumber(values.ghgEu ?? "0", decimalFormat) === 0 &&
      (values.ghgTotal !== TDV ||
        values.ghgTotal !== DDV ||
        values.ghgEtd !== DDV)
    ) {
      setError("ghgNoValue", {
        type: "value",
        message: "At least one GHG emission must be greater than 0",
      });

      isOthersValid = false;
    } else {
      clearErrors("ghgNoValue");
    }
    if (values.splitDetails?.length > 0) {
      values.splitDetails?.forEach((item, index) => {
        if (
          item.defaultValueAppliedFlag !== true &&
          convertToNumber(item.ghgEccr ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEccs ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEec ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEee ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEl ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEp ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEsca ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEtd ?? "0", decimalFormat) === 0 &&
          convertToNumber(item.ghgEu ?? "0", decimalFormat) === 0 &&
          (item.ghgTotal !== TDV ||
            item.ghgTotal !== DDV ||
            item.ghgEtd !== DDV)
        ) {
          setError(`splitDetails.${index}.ghgEec`, {
            type: "value",
            message: "At least one GHG emission must be greater than 0",
          });
          isOthersValid = false;
        } else {
          clearErrors(`splitDetails.${index}.ghgEec`);
        }
      });
    }
    if (values.euRedCompliantFlag == null) {
      setError("euRedCompliantFlagYes", {
        type: "value",
        message: "Please select EU RED Compliant Flag",
      });
      isOthersValid = false;
    }

    if (values.isccCompliantFlag == null) {
      setError("isccCompliantFlagYes", {
        type: "value",
        message: "Please select ISCC Compliant Flag",
      });
      isOthersValid = false;
    }

    const flags = {
      supplier: false,
      general: false,
      emission: false,
      certification: false,
      additionalInfo: false,
    };
    const tabsRequireAttention =
      divCode === DIV_CODE_SAF ? [] : ["additionalInfo"];

    Object.keys(tabFields).forEach((tab) => {
      const tabErrors = tabFields[tab].filter((field) => errors[field] != null);
      if (tabErrors.length > 0) {
        flags[tab] = true;
        tabsRequireAttention.push(tab);
      }
    });
    setInvalidTabs(flags);
    if (tabsRequireAttention.length > 0 && !openTabsOnce) {
      setOpen([...tabsRequireAttention]);
      setOpenTabsOnce(true);
    }

    return valid && isOthersValid;
  }, [
    trigger,
    getValues,
    divisionCode,
    hideLtpButton,
    divCode,
    setError,
    clearErrors,
    openTabsOnce,
    errors,
    decimalFormat,
  ]);

  useEffect(() => {
    // Make sure we trigger form validation after values change (e.g. after loading) to show errors
    // aka simulating next tick here
    setTimeout(() => {
      if (documentData) {
        validate();
      }
    }, 10);
  }, [docValues, validate, errorCount, documentData]);

  const validateAndSave = async (accept = false) => {
    if (accept) {
      const isValid = await validate();

      if (isValid) {
        setOperation(Accepting);
        const {emissionForTransport, splitDetails, ...data} = getValues();
        await updateDocument({
          variables: {
            event: getViewDocumentDetailsACCEPT(
              data,
              splitDetails,
              emissionForTransport,
              account,
              divisionId,
              siteReferenceId,
              docId,
              dateFormat,
              documentData,
              decimalFormat,
              convertToNumber,
            ),
          },
        });
      }
    } else {
      const {emissionForTransport, splitDetails, ...data} = getValues();

      setOperation(Rejecting);

      await updateDocument({
        variables: {
          event: getViewDocumentDetailsREJECT(
            data,
            splitDetails,
            emissionForTransport,
            account,
            divisionId,
            siteReferenceId,
            docId,
          ),
        },
      });
    }
  };

  const onSubmit = async (userAction) => {
    setSubmitLoading(true);
    if (userAction === "accept") {
      validateAndSave(true);
    } else {
      validateAndSave(false);
    }
  };

  const computeProps = (name, options) => {
    const {ref, ...props} = register(name, options);
    return {innerRef: ref, ...props};
  };

  const renderTitle = (docStatus) => {
    switch (docStatus) {
      case "requires_attention":
        return "Missing expected information";
      case "completed":
        return "Completed";
      case "ready_to_assign":
        return "Ready to assign";
      case "ready_to_accept":
        return "Ready to accept";
      case "rejected":
        return "Rejected";
      case "split":
        return "Split";

      default:
        return "Status information is not available";
    }
  };
  if (loading) {
    return (
      <div className="flex flex-col min-h-full items-center py-12 bp-core bg-light-gray-100">
        <Spinner />
      </div>
    );
  }
  const status = documentData?.state;

  const handleRejectMessage = (message) => {
    if (message === "") {
      trigger("rejectionReason");
      setRejectMessage("");
    } else {
      setRejectMessage(
        rejectReasons.filter((r) => r.key === message)[0].message,
      );
      clearErrors("rejectionReason");
    }
  };

  const breadcrumbItems = [
    {text: "BioVerse", link: "/"},
    {
      text: startCase(appSetting?.currentCountry?.toLowerCase() ?? ""),
      link: "/",
    },

    {
      text: "Document manager",
      link: {
        pathname: `/doc-manager/${selectedCountry}/`,
        search: `?pageNo=${searchParams.get("pageNo") ?? 0}&docId=${docId}`,
      },
    },
    {
      text: documentData?.sdNumber,
      link: {
        pathname: `/document-manager/incoming/${selectedCountry}/detailedView/${docId}`,
      },
    },
  ];

  const backButtonNavigation = () => {
    const backButtonSourceEncoded =
      searchParams.get("backButtonSource") ?? DOCUMENT_MANAGER_PAGE;
    const backButtonSource = decodeURIComponent(backButtonSourceEncoded);
    let backButtonPath = "";
    let searchParamsNeeded = "";

    if (backButtonSource === LINK_TO_PURCHASE_PAGE) {
      const ltpContractNumber = searchParams.get("ltpContractNumber");
      const ltpBalanceGroup = searchParams.get("ltpBalanceGroup");
      const ltpLocationGroup = searchParams.get("ltpLocationGroup");
      const ltpDocNumberSplit = searchParams.get("ltpDocNumberSplit");
      const preSelectionForLinkToPurchaseParams =
        `?contractNumber=${ltpContractNumber}` +
        `&balanceGroup=${ltpBalanceGroup}` +
        `&locationGroup=${ltpLocationGroup}` +
        `&docNumberSplit=${ltpDocNumberSplit}`;

      backButtonPath = `/link-to-purchase/${selectedCountry}/${divisionCode?.divisionCode}`;
      searchParamsNeeded = preSelectionForLinkToPurchaseParams;
    } else {
      backButtonPath = `/doc-manager/${selectedCountry}/`;
      searchParamsNeeded = `?pageNo=${
        searchParams.get("pageNo") ?? 0
      }&docId=${docId}`;
    }

    navigate({
      pathname: backButtonPath,
      search: searchParamsNeeded,
    });
  };

  const isDisabled =
    status !== undefined &&
    (status?.toLowerCase() === "completed" ||
      status?.toLowerCase() === "rejected" ||
      status?.toLowerCase() === "split" ||
      status?.toLowerCase() === "ready_to_assign");

  return (
    <div className="view-document flex flex-col view-doc__max-scroll bp-core bg-light-gray-100 ">
      <div className="flex flex-1 flex-row justify-between items-stretch min-h-full w-full">
        <div className="flex flex-col flex-1 max-h-screen overflow-y-auto">
          <Layout
            title={renderTitle(status)}
            breadcrumbs={breadcrumbItems}
            subtitle={
              status !== "completed" ? (
                <Subtitle
                  className="px-7"
                  status={status}
                  invalidTabs={invalidTabs}
                  onClick={(tab) => {
                    handleErrorMessageClick(tab);
                  }}
                  errors={isDisabled ? null : errors}
                  formData={docValues}
                />
              ) : (
                <span />
              )
            }
          >
            <div className="flex flex-col p-2 min-h-[800px] px-7 pt-6">
              <FormProvider {...methods}>
                <Form className="flex flex-col gap-5">
                  <FormGroup>
                    <Label for="sdNumber" className="fw-light">
                      Unique number of sustainability declaration
                    </Label>
                    <Input
                      type="text"
                      id="sdNumber"
                      {...computeProps("sdNumber", {
                        required: "Please enter a sd number",
                      })}
                      invalid={!!errors.sdNumber && !isDisabled}
                      maxLength={100}
                      disabled={isDisabled}
                    />
                    {errors.sdNumber && !isDisabled && (
                      <FormFeedback className="mt-0">
                        {errors.sdNumber.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                  <FormGroup>
                    <Label for="dateOfIssuance" className="fw-normal">
                      Date of issuance
                    </Label>
                    <Datepicker
                      id="dateOfIssuance"
                      name="dateOfIssuance"
                      {...computeProps("dateOfIssuance", {
                        required: "Please enter date of issuance",
                      })}
                      control={control}
                      error={isDisabled ? null : errors.dateOfIssuance}
                      rules={{required: "Please enter date of issuance"}}
                      disabled={isDisabled}
                    />
                    {errors.dateOfIssuance &&
                      !isDisabled &&
                      errors.dateOfIssuance.type !== "required" && (
                        <FormFeedback className="mt-0">
                          {errors.dateOfIssuance.message}
                        </FormFeedback>
                      )}
                  </FormGroup>

                  <Accordion open={open} {...{toggle}} flush>
                    <AccordionItem>
                      <AccordionHeader targetId="supplier">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.supplier && hasErrors && !isDisabled && (
                            <Alert32 color="#e64949" className="mr-[9px]" />
                          )}
                          Supplier and recipient information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="supplier"
                        className="mb-2 mt-6"
                      >
                        <SupplierAndRecipientInfo
                          status={status}
                          selectedCountry={selectedCountry}
                          isDisabled={isDisabled}
                          defaultValuesForm={docValues}
                          divisionCode={divisionCode?.divisionCode}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="general">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.general && hasErrors && !isDisabled && (
                            <Alert32 color="#e64949" className="mr-[9px]" />
                          )}
                          General information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="general"
                        className="mb-2 mt-6"
                      >
                        <GeneralInformation
                          status={status}
                          selectedCountry={selectedCountry}
                          countries={
                            documentDataItem?.bioLcCoproEuGetCountries ?? []
                          }
                          rawMaterialTypes={
                            documentDataItem?.bioLcGetMaterialDataApi
                              ?.rawMaterialNames ?? []
                          }
                          productTypeItems={
                            documentDataItem?.bioLcGetMaterialDataApi
                              ?.productNames ?? []
                          }
                          isDisabled={isDisabled}
                          divisionCode={divisionCode?.divisionCode}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="certification">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center justify-items-center h-8">
                          {invalidTabs.certification &&
                            hasErrors &&
                            !isDisabled && (
                              <Alert32 color="#e64949" className="mr-[9px]" />
                            )}
                          Scope of certification of raw material
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="certification"
                        className="mb-2 mt-6"
                      >
                        <ScopeOfCertification
                          status={status}
                          selectedCountry={selectedCountry}
                          isDisabled={isDisabled}
                        />
                      </AccordionBody>
                    </AccordionItem>
                    <AccordionItem>
                      <AccordionHeader targetId="emission">
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.emission && hasErrors && !isDisabled && (
                            <Alert32 color="#e64949" className="mr-[9px]" />
                          )}
                          Greenhouse gas (GHG) emission information
                        </div>
                      </AccordionHeader>
                      <AccordionBody
                        accordionId="emission"
                        className="mb-2 mt-6"
                      >
                        <GHGEmissionInfo
                          status={status}
                          countries={
                            documentDataItem?.bioLcCoproEuGetCountries ?? []
                          }
                          selectedCountry={selectedCountry}
                          isDisabled={isDisabled}
                          divisionCode={divisionCode?.divisionCode}
                        />
                      </AccordionBody>
                    </AccordionItem>

                    <AccordionItem>
                      <AccordionHeader
                        targetId="additionalInfo"
                        className="flex items-center"
                      >
                        <div className="accordion-title fw-light text-black text-xl opacity-100 flex flex-row items-center h-8">
                          {invalidTabs.additionalInfo &&
                            hasErrors &&
                            !isDisabled && (
                              <Alert32 color="#e64949" className="mr-[9px]" />
                            )}
                          Supplementary Information
                        </div>
                      </AccordionHeader>

                      <AccordionBody
                        accordionId="additionalInfo"
                        className="mb-4"
                      >
                        <SupplimentaryInfo
                          selectedCountry={selectedCountry}
                          status={status}
                          mbLocations={
                            documentDataItem?.bioLcGetLocationGroupApi
                              ?.locationGroups ?? []
                          }
                          mbBalance={
                            documentDataItem?.bioLcGetBalanceGroupApi
                              ?.balanceGroups ?? []
                          }
                          versioningDocs={
                            documentDataItem?.bioLcGetVersioningDocsApi
                              ?.documents ?? []
                          }
                          transportEmissions={
                            documentDataItem
                              ?.bioLcCoproConfigCenterTransportationEmission
                              ?.Records ?? []
                          }
                          isDisabled={isDisabled}
                          divisionCode={divisionCode?.divisionCode}
                        />
                      </AccordionBody>
                    </AccordionItem>
                  </Accordion>
                  <div className="flex flex-row justify-end items-center gap-4">
                    {(status === "rejected" ||
                      status === "completed" ||
                      status === "split" ||
                      (status === "ready_to_assign" &&
                        divisionCode?.divisionCode !== DIV_CODE_SAF)) && (
                      <Button
                        color="standard-primary"
                        className={
                          status === "rejected"
                            ? "show rounded-none"
                            : "primary-btn rounded-none"
                        }
                        type="button"
                        onClick={backButtonNavigation}
                      >
                        <TailLeft24 className="btn-icon-prefix" />
                        Back
                      </Button>
                    )}
                    {status?.toLowerCase() !== "rejected" &&
                      status?.toLowerCase() !== "completed" &&
                      !rejecting && (
                        <>
                          {(divisionCode?.divisionCode === DIV_CODE_SAF ||
                            (status !== "ready_to_assign" &&
                              status !== "split")) && (
                            <Button
                              color="tertiary"
                              className="show btn-bg-br-gray"
                              style={{
                                borderRadius: "0px",
                              }}
                              type="button"
                              onClick={() => {
                                setRejecting(!rejecting);
                              }}
                              disabled={rejecting}
                            >
                              <Remove24 className="btn-icon-prefix" />
                              Reject
                            </Button>
                          )}

                          <Button
                            type="button"
                            color="standard-primary btn-bg-br-black"
                            style={{
                              borderRadius: "0px",
                            }}
                            onClick={() => {
                              setAcceptModal(true);
                            }}
                            disabled={
                              Object.keys(errors).length !== 0 || rejecting
                            }
                          >
                            <Check24 className="btn-icon-prefix" />
                            {status === "ready_to_assign" ||
                            status === "split" ||
                            status === "completed"
                              ? "Update"
                              : "Accept"}
                          </Button>
                        </>
                      )}
                  </div>
                  {rejecting && (
                    <>
                      <FormGroup>
                        <Label for="rejectionReason" className="fw-normal mb-4">
                          Why are you rejecting?
                        </Label>

                        <Input
                          type="select"
                          id="rejectionReason"
                          data-test="rejectionReason"
                          {...computeProps("rejectionReason", {
                            required: "Please select reject reason",
                          })}
                          invalid={!!errors.rejectionReason}
                          maxLength={100}
                          placeholder="Please select a reason"
                          onChange={(e) => {
                            handleRejectMessage(e.target.value);
                            setSelectedOption(e.target.value);
                          }}
                        >
                          <option value="">Please select a reason</option>

                          {rejectReasons.length > 0 &&
                            rejectReasons.map((p) => (
                              <option key={p.key} value={p.key}>
                                {p.value}
                              </option>
                            ))}
                        </Input>

                        {errors.rejectionReason && (
                          <FormFeedback className="mt-2">
                            {errors.rejectionReason.message}
                          </FormFeedback>
                        )}
                      </FormGroup>
                      {selectedOption === "other" && (
                        <FormGroup>
                          <Label
                            for="rejectionReasonNotes"
                            className="fw-normal mb-4"
                          >
                            Notes
                          </Label>
                          <Input
                            type="textarea"
                            rows="3"
                            id="rejectionReasonNotes"
                            data-test="rejectionReasonNotes"
                            {...computeProps("rejectionReasonNotes")}
                            invalid={!!errors.rejectionReasonNotes}
                            maxLength={200}
                            disabled={!rejecting}
                          />
                        </FormGroup>
                      )}

                      <span className="">{rejectMessage}</span>
                      <div className="flex flex-row justify-end items-center gap-4">
                        <Button
                          color="tertiary"
                          className="show btn-bg-br-gray"
                          style={{
                            borderRadius: "0px",
                          }}
                          type="button"
                          onClick={() => {
                            setRejecting(!rejecting);
                            clearErrors("rejectionReason");
                          }}
                          disabled={!rejecting}
                        >
                          <Remove24 className="btn-icon-prefix" />
                          Cancel
                        </Button>

                        <Button
                          type="button"
                          color="standard-primary btn-bg-br-black"
                          style={{
                            borderRadius: "0px",
                          }}
                          onClick={() => onSubmit("reject")}
                          disabled={errors.rejectionReason}
                        >
                          {submitLoading ? (
                            <Spinner size="sm" className="btn-icon-prefix" />
                          ) : (
                            <Check24 className="btn-icon-prefix" />
                          )}
                          Reject
                        </Button>
                      </div>
                    </>
                  )}
                </Form>
              </FormProvider>
            </div>
          </Layout>
        </div>
        <SingleDocumentViewer
          document={{downloadUrl: documentFile?.downloadUrl}}
        />
        <Modal size="sm" isOpen={acceptModal} className="modal-dialog-centered">
          <ModalBody className="text-center">
            <p className="mb-[2px]">
              {`Confirm ${status === "ready_to_assign" ? "update" : "accept"}`}
            </p>
            <p className="text-[13px] text-gray-800 mb-0">
              {`Are you sure you want to ${
                status === "ready_to_assign" ? "update" : "accept"
              } this document?`}
            </p>
          </ModalBody>
          <ModalFooter className="p-0 d-block">
            <div className="row g-0 m-0 modal-footer-row">
              <div className="col-6 d-grid">
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 !py-[10px] opacity-80 opacity-100-hover bg-transparent"
                  onClick={() => {
                    setAcceptModal(false);
                  }}
                  disabled={submitLoading}
                >
                  Cancel
                </Button>
              </div>
              <div className="col-6 d-grid">
                <Button
                  color="darker-tertiary"
                  size="lg"
                  className="border-0 rounded-0 !py-[10px] bg-transparent text-default"
                  onClick={() => onSubmit("accept")}
                  disabled={submitLoading}
                >
                  {submitLoading && (
                    <Spinner size="sm" className="btn-icon-prefix" />
                  )}
                  {status === "ready_to_assign" ? "Update" : "Accept"}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    </div>
  );
};

export default DocumentDetailedView;
