import {DDV, TDV} from "modules/DocManager/components/view-document/utils";
import {
  convertToNumber,
  formatNumber,
  useUserSettings,
} from "providers/userSettings";

export default function parseAndRoundToTwoDecimal(value) {
  const num = parseFloat(value ?? "0");
  return parseFloat((Math.round(num * 100) / 100).toFixed(2));
}

export function parseAndRoundToThreeDecimal(value) {
  const num = parseFloat(value ?? "0");
  const formattedNumber = parseFloat(num.toFixed(3));
  return formattedNumber;
}

export function formatNumberIfExists(
  value,
  decimalFormatNew,
  decimalPoints = 2,
) {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();

  if (value && decimalPoints === 3) {
    return formatNumber(parseAndRoundToThreeDecimal(value), decimalFormat, 3);
  }

  if (value && decimalPoints === 2) {
    return formatNumber(parseAndRoundToTwoDecimal(value), decimalFormat, 2);
  }
  return value;
}

export const handleNumberAndDDV = (event) => {
  // Allowed keys: numbers, dot, comma, backspace, tab, escape, enter, and arrow keys and D,V
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    ",",
    "Backspace",
    "Tab",
    "Escape",
    "Enter",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "D",
    "V",
  ];
  if (!allowedKeys.includes(event.key) && !event.ctrlKey && !event.metaKey) {
    event.preventDefault();
  }
};

export const handleNumberDDVAndTDV = (event) => {
  // Allowed keys: numbers, dot, comma, backspace, tab, escape, enter, and arrow keys and T, D, V
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    ",",
    "Backspace",
    "Tab",
    "Escape",
    "Enter",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
    "T",
    "D",
    "V",
  ];
  if (!allowedKeys.includes(event.key) && !event.ctrlKey && !event.metaKey) {
    event.preventDefault();
  }
};

export const handleNumber = (event) => {
  // Allowed keys: numbers, dot, comma, backspace, tab, escape, enter, and arrow keys
  const allowedKeys = [
    "0",
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8",
    "9",
    ".",
    ",",
    "Backspace",
    "Tab",
    "Escape",
    "Enter",
    "ArrowLeft",
    "ArrowRight",
    "ArrowUp",
    "ArrowDown",
  ];
  if (!allowedKeys.includes(event.key) && !event.ctrlKey && !event.metaKey) {
    event.preventDefault();
  }
};

// number input pattern which allows only numbers, dot, and comma
export const getNumberInputPattern = (decimalFormat) => {
  const numberInputPattern = {
    pattern:
      decimalFormat === "European Style"
        ? {
            value: /^\d+(?:\.\d+)*(?:,\d{1,3})?$|^\d+(?:,)?\d{1,3}$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point)",
          }
        : {
            value: /^(\d+(,\d+)*(\.\d{1,3})?)$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point)",
          },
    validate: (value) => {
      // Additional validation to ensure the value is not bigger than 10 digits before any separators
      if (value) {
        const numberValue = convertToNumber(value, decimalFormat);
        const numericSplit = numberValue?.toString().split(".");
        const numericPart = numericSplit && numericSplit[0];
        const decimalPart = numericSplit && numericSplit[1];

        if (
          (numericPart && numericPart.length > 10) ||
          (decimalPart && decimalPart.length > 3)
        ) {
          return "Value must be number with (max 10 digits before and 3 digits after decimal point) ";
        }
      }
      return true;
    },
  };
  return numberInputPattern;
};

// number input pattern which allows only numbers, dot, and comma, DDV
export const getDDVNumberInputPattern = (decimalFormat) => {
  const numberInputPattern = {
    pattern:
      decimalFormat === "European Style"
        ? {
            value: /^(DDV|\d+(?:\.\d+)*(?:,\d{1,3})?$|^\d+(?:,)?\d{1,3})$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point) or DDV",
          }
        : {
            value: /^(DDV|\d+(,\d+)*(\.\d{1,3})?)$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point) or DDV",
          },
    validate: (value) => {
      if (value === "DDV") {
        return true;
      }
      // Additional validation to ensure the value is not bigger than 10 digits before any separators
      if (value) {
        const numberValue = convertToNumber(value, decimalFormat);
        const numericSplit = numberValue?.toString().split(".");
        const numericPart = numericSplit && numericSplit[0];
        const decimalPart = numericSplit && numericSplit[1];

        if (
          (numericPart && numericPart.length > 10) ||
          (decimalPart && decimalPart.length > 3)
        ) {
          return "Value must be number with (max 10 digits before and 3 digits after decimal point)  or DDV";
        }
      }
      return true;
    },
  };
  return numberInputPattern;
};

// number input pattern which allows only numbers, dot, and comma, DDV and TDV
export const getDDVTDVNumberInputPattern = (decimalFormat) => {
  const numberInputPattern = {
    pattern:
      decimalFormat === "European Style"
        ? {
            value: /^(DDV|TDV|\d+(?:\.\d+)*(?:,\d{1,3})?$|^\d+(?:,)?\d{1,3})$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point) or TDV or DDV",
          }
        : {
            value: /^(DDV|TDV|\d+(,\d+)*(\.\d{1,3})?)$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point) or TDV or DDV",
          },

    validate: (value) => {
      if (value === "TDV" || value === "DDV") {
        return true;
      }

      // Additional validation to ensure the value is not bigger than 10 digits before any separators
      if (value) {
        const numberValue = convertToNumber(value, decimalFormat);
        const numericSplit = numberValue?.toString().split(".");
        const numericPart = numericSplit && numericSplit[0];
        const decimalPart = numericSplit && numericSplit[1];

        if (
          (numericPart && numericPart.length > 10) ||
          (decimalPart && decimalPart.length > 3)
        ) {
          return "Value must be number with (max 10 digits before and 3 digits after decimal point) or TDV or DDV";
        }
      }
      return true;
    },
  };
  return numberInputPattern;
};

// number input pattern which allows only numbers, dot, and comma
export const getRequiredNumberInputPattern = (decimalFormat) => {
  const numberInputPattern = {
    pattern:
      decimalFormat === "European Style"
        ? {
            value: /^\d+(?:\.\d+)*(?:,\d{1,3})?$|^\d+(?:,)?\d{1,3}$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point)",
          }
        : {
            value: /^(\d+(,\d+)*(\.\d{1,3})?)$/,
            message:
              "Please enter a valid quantity with (max 3 digits after decimal point)",
          },
    validate: (value) => {
      // Additional validation to ensure the value is not bigger than 10 digits before any separators

      if (value) {
        const numberValue = convertToNumber(value, decimalFormat);
        const numericSplit = numberValue?.toString().split(".");
        const numericPart = numericSplit && numericSplit[0];
        const decimalPart = numericSplit && numericSplit[1];

        if (
          (numericPart && numericPart.length > 10) ||
          (decimalPart && decimalPart.length > 3)
        ) {
          return "Value must be number with (max 10 digits before and 3 digits after decimal point) ";
        }

        if (numberValue === 0 || numberValue < 0) {
          return "Please enter a valid quantity";
        }
      }

      return true;
    },
  };
  return numberInputPattern;
};

export const getFormattedDecimal = (cell, decimalNumbers = 2) => {
  const {
    userSettings: {decimalFormat},
  } = useUserSettings();
  if (cell.getValue()) {
    if (cell.getValue() === TDV || cell.getValue() === DDV) {
      return cell.getValue();
    }
    const formattedNumber =
      decimalNumbers === 2
        ? parseAndRoundToTwoDecimal(cell.getValue())
        : parseAndRoundToThreeDecimal(cell.getValue());

    return formatNumber(formattedNumber, decimalFormat, decimalNumbers);
  }
  return cell.getValue();
};
